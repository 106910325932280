import { ListItem, UnorderedList } from '@chakra-ui/react'
import { GrandSection } from 'ui'
import { type Language } from 'ecosystem'

interface SearchPageEmptyProps {
  searchQuery?: string
  language?: Language
}

export const SearchPageEmptyResult = ({
  searchQuery = '',
  language = 'sv'
}: SearchPageEmptyProps) => {
  if (language === 'sv') {
    return (
      <GrandSection
        sectionId="searchPage"
        title={`Inga resultat${
          searchQuery.length ? ` för «${searchQuery}»` : ''
        }. Försök igen, eller se förslag nedan.`}>
        <UnorderedList>
          <ListItem>
            Du kan t.ex. söka efter produktnamn, produktkod, produkttyp, kategori eller varumärke.
          </ListItem>
          <ListItem>
            När du skriver i sökfältet rekommenderar systemet söktermer medan du skriver. Detta för
            att hjälpa dig hitta rätt produkt.
          </ListItem>
          <ListItem>Använd menyn för produktkategori för att hitta produkter.</ListItem>
        </UnorderedList>
      </GrandSection>
    )
  }

  if (language === 'no') {
    return (
      <GrandSection
        sectionId="searchPage"
        title={`Ingen resultater${
          searchQuery.length ? ` til «${searchQuery}»` : ''
        }. Prøv igjen, eller se forslag nedenfor.`}>
        <UnorderedList>
          <ListItem>
            Du kan for eksempel: søk etter produktnavn, produktkode, produkttype, kategori eller
            merke.
          </ListItem>
          <ListItem>
            Når du skriver i søkefeltet, anbefaler systemet søkeord mens du skriver. Dette er for å
            hjelpe deg med å finne riktig produkt.
          </ListItem>
          <ListItem>Bruk produktkategorimenyen for å finne produkter.</ListItem>
        </UnorderedList>
      </GrandSection>
    )
  }

  if (language === 'de') {
    return (
      <GrandSection
        sectionId="searchPage"
        title={`Keine Ergebnisse${
          searchQuery.length ? ` für «${searchQuery}»` : ''
        }. Versuchen Sie es erneut oder sehen Sie sich die untenstehenden Vorschläge an.`}>
        <UnorderedList>
          <ListItem>
            Sie können z. B. nach Produktnamen, Produktcode, Produkttyp, Kategorie oder Marke
            suchen.
          </ListItem>
          <ListItem>
            Während Sie in das Suchfeld eingeben, empfiehlt das System automatisch Suchbegriffe.
            Dies soll Ihnen helfen, das richtige Produkt zu finden.
          </ListItem>
          <ListItem>Verwenden Sie das Produktkategorien-Menü, um Produkte zu finden.</ListItem>
        </UnorderedList>
      </GrandSection>
    )
  }

  return (
    <GrandSection
      sectionId="searchPage"
      title={`No results${
        searchQuery.length ? ` for «${searchQuery}»` : ''
      }. Try again, or see suggestions below.`}>
      <UnorderedList>
        <ListItem>
          You can e.g. search by product name, product code, product type, category or brand.
        </ListItem>
        <ListItem>
          As you type in the search field, the system recommends search terms as you type. This is
          to help you find the right product.
        </ListItem>
        <ListItem>Use the product category menu to find products.</ListItem>
      </UnorderedList>
    </GrandSection>
  )
}
