import type { MouseEventHandler } from 'react'
import { Center, HStack, Text } from '@chakra-ui/react'
import { BiSearch } from 'react-icons/bi'
import { GrandLink, ImageBox, Price } from 'ui'
import type { StorefrontSearchItem } from '../types'

interface SearchResultsItemProps {
  item: StorefrontSearchItem
  onClick: MouseEventHandler<HTMLDivElement>
}

export const SearchResultsItem = ({ item, onClick }: SearchResultsItemProps) => {
  return (
    <HStack
      _hover={{
        bg: 'background.translucent',
        textDecoration: 'none'
      }}
      as={GrandLink}
      gap={4}
      href={item.slug}
      onClick={onClick}
      px={6}
      py={4}>
      {(() => {
        switch (item.type) {
          case 'PRODUCT':
            return (
              <>
                <ImageBox
                  h="40px"
                  imageProps={{
                    alt: item.text,
                    src: item.image || ''
                  }}
                  minW="40px"
                  w="40px"
                />
                <Text>{item.text}</Text>

                {!!item.data && Number.isInteger(item.data.price) && (
                  <Price
                    alignItems="baseline"
                    orientation="horizontal"
                    originalPrice={item.data.originalPrice}
                    price={item.data.price}
                    textDecoration="none"
                  />
                )}
              </>
            )
          case 'SEARCH':
            return (
              <>
                <Center color="text.mild" fontSize="3xl" h="30px" minW="40px">
                  <BiSearch />
                </Center>

                <Text>{item.text}</Text>
              </>
            )
          default:
            return null
        }
      })()}
    </HStack>
  )
}
